.OGKushPage {
    margin: 1.5% 0% 0px 0px;
    /*float: right;
    width: 78%;*/
    display: flex;
    flex-direction: column;
}

.OGKushContent {
    /*float: left;
    width: 80%;*/
    height: auto;
    background-color: #B586A9;
}

.OGKushContent img {
    width: 500px;
}

.OGKushText {
    padding: 40px;
    padding-top: 0px;
    font-size: 1.1em;
    font-family: Regular;
}

.OGKushText table {
    width: 100%;
    text-align: left;
    font-family: Regular;
}

#FeatureHeaders {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    font-family: Bold;
    font-size: 1em
}

#FeatureSubHeaders {
    text-decoration: underline;
    text-decoration-thickness: 2px;
}

@media screen and (max-width: 700px) {
    .OGKushPage {
        margin: 0 0% 0px 0px;
        padding-top: 250px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .OGKushContent {
        width: 85%;
        height: auto;
        background-color: #B586A9;
    }
    
    .OGKushContent img {
        width: calc(100% - 60px);
        max-width: 330px;
        padding: 6px;
    }
    
    .OGKushText {
        padding: 30px;
        text-align: justify;
        padding-top: 0px;
        font-size: 1.1em;
        font-family: Regular;
    }
    
    .OGKushText table {
        width: 100%;
        text-align: left;
        font-family: Regular;
    }
    
    #FeatureHeaders {
        text-decoration: underline;
        text-decoration-thickness: 2px;
        font-family: Bold;
        font-size: 1em
    }
    
    #FeatureSubHeaders {
        text-decoration: underline;
        text-decoration-thickness: 2px;
    }
}




