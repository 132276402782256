@media screen and (max-width: 700px) {
    .MobileNewsletter {
        position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 80%;
            margin: 250px 0px 0px 10%;
    }
    
    #HeaderText span {
        margin-right: 5px;
    }
    
    .MobileNewsletterContent {
        background-color: #EEBc34;
        width: 100%;
    }
    
    .MobileNewsletterText {
        padding: 20px;
        font-size: 15px;
        font-family: Regular;
    }
    
    .MobileNewsletterText .ProvinceList .LeftList a{
        font-size: 20px;
    }



    /* BC popup "TO FIND BZAM NEAR YOU EMAIL"; used on multiple pages (homepage and buy page) so it's not in the ".where" BEM block */
    .MobileNewsletter .find-in-bc-infobox { width: 326px; max-width: calc(65vw - 24%); font-size: 16px; }

    .MobileNewsletter .find-in-bc-infobox.is-visible { display: block; }
    
    .MobileNewsletter .ProvinceWrapper::after { right: 14px; }
    .MobileNewsletter #Province { padding-left: 14px; padding-right: 36px; margin-bottom: 10px; font-size: 15px;}
    .MobileNewsletter .GoButton { margin-left: 10px; margin-bottom: 10px; }

    .MobileNewsletter .NewsletterDivider { margin-top: 14px; }

}


