:root {
    --flower-color   : #EEBc34;
    --pre-rolls-color: #b685ab;
    --vape-color     : #1a7fbd;
}

.Products {
    margin: 0px 0px 0px 0px;
    /*float: right;
    width: 78%;*/
}

#HeaderText {
/* INherited from About */
}



.DriedFlower, .PreRolls {
    padding-bottom: 20px;
}

.DriedFlower, .Vape {
/*    background-color: var(--flower-color);*/
/*    padding: 30px;*/
}

.ProductContent {
    display: flex;
    flex-direction: row;
    font-family: Regular;
    font-size: var(--body-font-size-stretchy);
    /*max-width: 800px;*/
}


.ProductDescription {
    display: flex;
}


.ProductDescription__button {
    margin-right: 20px;
    min-width: 200px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}
.ProductDescription__button--flower { border: 3px solid var(--flower-color); background: url('../../assets/img/Products_Flower.jpg') center center / cover no-repeat; }
.ProductDescription__button--pre-rolls { border: 3px solid var(--pre-rolls-color); background: url('../../assets/img/Products_Prerolls.jpg') center center / cover no-repeat; }
.ProductDescription__button--vape { border: 3px solid var(--vape-color); background: url('../../assets/img/Products_Vape.jpg') center center / cover no-repeat; }

.ProductDescription__button-image { 
    width: 66%;
}


.ProductDescription__text {
    padding: 30px;
}
.ProductDescription__text--flower { background-color: var(--flower-color); }
.ProductDescription__text--pre-rolls { background-color: var(--pre-rolls-color); }
.ProductDescription__text--vape { background-color: var(--vape-color); }

.ProductDescription__heading {
    margin: 0px;
    padding: 0px;
}

.ProductDescription__p {
    margin-top: .2em;
}




.ProductDescription img {
    /*height: auto;
    display: block;
    width: auto;
    max-width: 200px;
    max-height: 200px;
    padding-left: 30px;*/
}

.ProductDescription ul {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 0;
}

.ProductFeatures li {
    font-family: bold;
    display: block;
    flex: 0 1 auto;
}


@media only screen and (max-width: 959px)
{
    .ProductDescription__button { margin-right: 12px; }
    .ProductDescription__text { padding: 20px; }
}