:root {

    /* colors - bgs */
    --newsletter-bg-color: #2480bb;
    --newsletter-checkbox-bg-color: #ffffff;
    --newsletter-checkbox-check-color: #2480bb;
    
    /* colors - fonts */
    --newsletter-heading-font-color: #eebc34;
    --newsletter-main-font-color: #ffffff;
    --newsletter-input-font-color: #000000;
    
    /* font sizes  */
    --newsletter-subheading-font-size-stretchy: clamp(20px, 1.6vw, 24px);

    /* other */
    --newsletter-input-height: 50px;
    --newsletter-province-container-width: 216px;

}

/*.Newsletter {
    margin: 100px 0px 40px 50px;
    float: right;
    width: 78%;
    background: blue;
}
*/

#HeaderText {
/* INherited from About */
}

.NewsletterContent {
    display: flex;
    flex-direction: row;
    font-family: Regular;
    font-size: 1.2vw;
    /*width: 800px;*/
}

.NewsletterText {
    background-color: #EEBc34;
    /*width: 80%;*/
    width: 100%;
    padding: 30px;
}

.ProvinceList {
    display: flex;
    position: relative;
}

.ProvinceList a {
    display: block;
    font-size: 2vw;
    padding: 8px;
    color: #000;    
    text-decoration: underline;
    text-decoration-thickness: 2px;
    position: relative;
}

#Unavailable {
    color: gray;
    text-decoration: line-through;
}


.NewsletterDivider { margin-top: 24px; margin-bottom: 24px; border-top: 1px solid #000; }


/* BC popup "TO FIND BZAM NEAR YOU EMAIL"; used on multiple pages (homepage and buy page) so it's not in the ".where" BEM block */
.find-in-bc-infobox { display: none; width: 46vw; /*max-width: 70vw;*/ text-align: center; font-size: 2vw; line-height: 1; background: #fff; color: black; padding: 14px 8px 6px 8px; top: 18px; /*position: absolute; left: 60px;*/ box-shadow: 0 0 6px 0px rgb(0 0 0 / 70%); z-index: 3; margin-top: 10px; }

@media only screen and (min-width: 1000px)
{
    .find-in-bc-infobox { width: 460px; font-size: 20px; }
}

.find-in-bc-infobox:hover { cursor: pointer; }

.find-in-bc-infobox.is-visible { display: block; }



.GoButton {
    margin-left: 10px;
    width: 70px;
    height: auto;
    transition: all .2s ease-in-out;
    vertical-align: bottom;
}

.GoButton:hover {
    cursor: pointer;
}




/*body.newsletter-page:not(.newsletter-modal) .SideBar { display: none; }
body.newsletter-page:not(.newsletter-modal) .MobileSideBar { display: none; }*/

.TableTopLogoMain--newsletter img { display: none; max-width: 700px; margin-bottom: 30px; }





/* -------------------------------------------------------------------------- */
/* Newsletter wrapper (modal)
/* -------------------------------------------------------------------------- */

.newsletter-modal { display: none; position: fixed; top: 0; bottom: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,.9); z-index: 999; }
.newsletter-modal[data-newsletter-modal="visible"] { display: block; }

.newsletter-modal__content { position: fixed;
    left: 50%;
    margin-left: -300px;
    top: 10%;
    width: 90%;
    heights: 80%;
    max-width: 600px;
    /*background: url(/img/newsletter-modal__bg.jpg) center center/cover var(--orange);*/
    overflow-ys: scroll;
    z-index: 999;
    display: flex;
    flex-flow: column nowrap; }

.newsletter-modal .TableTopLogoMain--newsletter { display: none; }

.newsletter-modal__close { position: absolute;
    top: 30px;
    right: 30px;
    /*width: 40px;
    height: 40px;*/ borders:solid 1px red;
    border-radiuss: 21px;
    text-align: center;
    margin: 0;
    font-size: 44px;
    line-height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    text-decoration: none; }

.newsletter-modal__logo-and-gif { display: flex; align-items: center; justify-content: center; min-height: min(30vh, 400px); background: url('./Background/Newsletter.gif') center center / cover no-repeat; }

.newsletter-modal__logo { flex-basis: clamp(200px, 80%, 400px); }


/* when modal is showing, certain things from the Newsletter form are hidden */

.newsletter-modal .newsletter { margin: auto; }
.newsletter-modal .newsletter__heading { display: none; }
.newsletter-modal .newsletter__address { display: none; }
.newsletter-modal .newsletter__submit { flex-basis: 100%; justify-content: center; }


@media only screen and (max-width: 700px)
{
    .newsletter-modal { background: var(--newsletter-bg-color); }
    .newsletter-modal__content { margin-left: 0; margin-right: 0; width: 100%; height: 100%; top: 0; left: 0; }
}


/* -------------------------------------------------------------------------- */
/* Newsletter form
/* -------------------------------------------------------------------------- */



.newsletter,
.newsletter * { box-sizing: border-box; }

.newsletter { background: var(--newsletter-bg-color); padding: 20px 80px; font-family: Regular; displays: none; /* FOR NOW */ }

.newsletter__heading-wrapper { margin-bottom: 30px; }

.newsletter__heading { font-family: Regular; font-size: 40px; text-align: center; text-transform: none; margin-bottom: 24px; color: var(--newsletter-heading-font-color); font-family: Regular; font-weight: bold; }
.newsletter__subheading { font-family: Regular; text-align: center; font-size: var(--newsletter-subheading-font-size-stretchy); color: var(--newsletter-heading-font-color); font-family: Regular; font-weight: normal; }

@media only screen and (max-width: 959px)
{
  .newsletter__heading-wrapper { max-width: 580px; margin-left: auto; margin-right: auto; }
/*  .newsletter__heading-br { display: none }*/
  .newsletter__subheading-br { display: none }
}

.newsletter__thanks-for-joining { display: none; /* JS shows this when ready */ max-width: 500px; margin: 60px auto 0; color: var(--newsletter-main-font-color); text-align: center; /*z-index: 2;*/ position: relative; font-size: var(--newsletter-subheading-font-size-stretchy); }

.newsletter__form { padding-bottom: 15px; z-index: 3; position: relative; /* needed for z-index to work */ }
.newsletter__form-inner { position: relative; display: flex; flex-flow: row wrap; justify-content: space-between; margin-bottom: 20px; gap: 16px; }

.newsletter__required { color: var(--newsletter-main-font-color); margin: 0 0 8px; }

.newsletter__input { flex: 0 0 calc(50% - 8px); max-width: calc(50% - 8px); margin: 0; padding: 14px 14px; border: 1px solid var(--newsletter-main-font-color); border-radius: 0; /* prevents iOS from adding this radius automatically */ font-size: var(--body-font-size); height: var(--newsletter-input-height); outline: none !important; background: #ffffff; color: var(--newsletter-input-font-color); position: relative; font-family: Regular; font-weight: bold; }
.newsletter__input::placeholder { color: var(--newsletter-input-font-color); text-transform: uppercase; }
.newsletter__input--email { /*flex: 0 0 100%; max-width: 100%;*/ flex: 0 1 calc(100% - var(--newsletter-province-container-width) - 16px); max-width: calc(100% - var(--newsletter-province-container-width) - 16px); }

.newsletter__province-container { flex: 0 0 var(--newsletter-province-container-width); height: var(--newsletter-input-height); outline: none !important; color: var(--newsletter-input-font-color); position: relative; display: flex; flex-flow: row wrap; align-items: center; font-weight: bold; background: #ffffff; text-transform: uppercase;  }
.newsletter__province-container {  }

.newsletter__province-selected { max-width: 80%; margin-top: 8px; }
.newsletter__province-plus-symbol { margin-top: 5px; /* looks vertically centered */ }
/*.newsletter__province-container[data-newsletter] { }*/

.newsletter__disclainers-and-submit { display: flex; flex-flow: row wrap; gap: 20px 36px; align-items: stretch; }
.newsletter__disclainers { flex: 0 0 100%; }




.newsletter__address-text { color: var(--newsletter-main-font-color); }

.newsletter__email-address-link { color: var(--newsletter-main-font-color); text-decoration: none; }

.newsletter__province-dropdown-toggle { display: flex; flex-flow: row nowrap; flex: 0 0 100%; justify-content: space-between; margin: 0 0 0 0; border: 1px solid var(--newsletter-main-font-color); padding: 0 14px; font-size: var(--body-font-size); height: var(--newsletter-input-height); outline: none !important; position: relative; display: flex; flex-flow: row wrap; align-items: center; color: var(--newsletter-input-font-color); text-decoration: none; }
.newsletter__province-dropdown-toggle[data-newsletter-province-dropdown-toggle="on"] { background: var(--opposite-of-main-font-color); }
.newsletter__province-dropdown-arrow { width: 24px; }

.newsletter__province-dropdown { display: none; position: absolute; width: 100%; margin-top: calc(var(--newsletter-input-height) - 0px); margin-bottom: 20px; top: -1px; left: 0; border: 1px solid var(--newsletter-main-font-color); border-top: none; background-color: #ffffff; padding-top: 0px; padding-bottom: 16px; z-index: 3; font-weight: bold; }
.newsletter__province-link { display: block; padding: 8px 14px; font-size: var(--body-font-size); color: var(--newsletter-input-font-color); text-decoration: none; }
.newsletter__province-link:hover { background-color: #ccc; }
[data-newsletter-province-selected="true"] { display: none; /* hide the selected province */ }



.newsletter__address-and-submit { flex: 0 0 100%; display: flex; flex-flow: row wrap; }
.newsletter__address { flex: 1; }
.newsletter__submit { flex: 0 0 200px; display: flex; align-items: stretch; justify-content: flex-end; }

.newsletter__submit-link { display: flex; flex-flow: row nowrap; align-items: center; justify-content: center; gap: 16px; min-height: 108px; min-width: calc(108px * (558 / 342)); background: url('SignMeUpBG.png') center center / contain no-repeat; color: var(--opposite-of-main-font-color); widths: 60px; height: 100%; padding: 16px 0; font-size: 22px; }
.newsletter__submit-link[data-newsletter-submit-link="enabled"]:hover { background-color: var(--orange); }
.newsletter__submit-link[data-newsletter-submit-link="disabled"] { opacity: .5; } 
.newsletter__submit-link[data-newsletter-submit="submitting"] { cursor: default; } 
/*.newsletter__submit-link[data-newsletter-submit-link="enabled"] { background: var(--orange); color: var(--yellow); }
.newsletter__submit-link[data-newsletter-submit-link="enabled"]:hover .bounce-right-when-hovered-if-enabled,
.newsletter__submit-link[data-newsletter-submit-link="enabled"]:active .bounce-right-when-hovered-if-enabled {
  -webkit-animation : bounce_right .15s ease-in-out 0s 2 alternate;
  -moz-animation    : bounce_right .15s ease-in-out 0s 2 alternate;
  -ms-animation     : bounce_right .15s ease-in-out 0s 2 alternate;
  -o-animation      : bounce_right .15s ease-in-out 0s 2 alternate;
  animation         : bounce_right .15s ease-in-out 0s 2 alternate;
}*/

.newsletter__submit-text { displays: none; }
.newsletter__submit-arrow { width: 80px; }

.newsletter__form-disclaimer-label { display: flex; margin-top: 12px; margin-bottom: 15px; position: relative; padding-left: 45px; cursor: pointer; font-size: 20px; }
.newsletter__form-disclaimer-label--last { margin-bottom: 0; }
.newsletter__form-disclaimer-checkbox { flex-basis: 0px; width: 0; margin: 0; visibility: hidden; /* hide the default checkbox */ /*flex: 0 0 20px; margin: 0 16px 0 0; width: 20px; height: 20px; background-color: var(--yellow);*/ }
.newsletter__form-disclaimer-checkbox-pretty { flex: 0 0 20px; margin: 0 14px 0 0; border: 1px solid var(--newsletter-main-font-color); background-color: var(--newsletter-main-font-color); position: absolute; top: 0; left: 0; height: 25px; width: 25px; }
.newsletter__form-disclaimer-text { flex: 1; /*bulk-font-edit-5.45*/; font-size: 13px; color: var(--newsletter-main-font-color); line-height: 1.3; }
.newsletter__form-disclaimer-link { color: var(--newsletter-main-font-color); }



/* custom checkbox styling on hover/click/checked etc ... */
  
/* specify the background color to be shown when hovering over checkbox */
.newsletter__form-disclaimer-label:hover input ~ .newsletter__form-disclaimer-checkbox-pretty { background-color: var(--newsletter-checkbox-bg-color); }
  
/* specify the background color to be shown when checkbox is active */
.newsletter__form-disclaimer-label input:active ~ .newsletter__form-disclaimer-checkbox-pretty { background-color: var(--newsletter-checkbox-bg-color); }
  
/* specify the background color to be shown when checkbox is checked */
.newsletter__form-disclaimer-label input:checked ~ .newsletter__form-disclaimer-checkbox-pretty { background-color: var(--newsletter-checkbox-bg-color); }
  
/* checkmark to be shown in checkbox; not be shown when not checked */
.newsletter__form-disclaimer-checkbox-pretty:after { content: ""; position: absolute; display: none; }
  
/* display checkmark when checked */
.newsletter__form-disclaimer-label input:checked ~ .newsletter__form-disclaimer-checkbox-pretty:after { display: block; }
  
/* checkmark styling; rotated the rectangle by 45 degreed and showing only two borders to make it look like a tickmark */
.newsletter__form-disclaimer-label .newsletter__form-disclaimer-checkbox-pretty:after { left: 8px; bottom: 5px; width: 5px; height: 12px; border: solid var(--newsletter-checkbox-check-color); border-width: 0 4px 4px 0; -webkit-transform: rotate(45deg); -ms-transform: rotate(45deg); transform: rotate(45deg); }


@media only screen and (max-width: 1099px)
{
  .newsletter__disclainers-and-submit { justify-content: center; }
  .newsletter__disclainers { flex-basis: 100%; }
  .newsletter__submit { flex-basiss: 50%; }
}

@media only screen and (max-width: 930px)
{
  .newsletter{ padding-left: 50px; padding-right: 50px; }
  .newsletter__input { flex-basis: calc(50% - 8px); max-width: calc(50% - 8px); }
  .newsletter__province-container { flex-basis: calc(50% - 8px); max-width: calc(50% - 8px); }
}


@media only screen and (max-width: 800px)
{

  .newsletter{ padding-left: 30px; padding-right: 30px; }
  .newsletter__submit { flex: 0 0 100%; justify-content: center; }

  .newsletter__submit { order: 1; }
  .newsletter__address { flex-shrink: 1; order: 2; margin-top: 20px; text-align: center; }

  

}

@media only screen and (min-width: 701px)
{
    .HeaderText--newsletter-desktop { background-color: #2480BB; }
    .HeaderText--newsletter-mobile { display: none; }
    .MobileFooter { display: none; }
}

@media only screen and (max-width: 700px)
{
    /*this must exist, but it can be empty I think, otherwise the mobile menu doesn't top-align properly on this page, because of how this was all originally set up*/
    .TableTopLogoMain--newsletter { displays: none; }
    .TableTopLogoMain--newsletter img { display: none; /* this is hacky (would rather hide the parent div) but this is the quick fix that works within the current system so that the mobile menu still gets positioned correctly */ }

    .newsletter-header-wrapper { padding-top: 250px; display: flex; flex-flow: column; justify-content: center;
    align-items: center;
    widths: 80%; }

    .HeaderText--newsletter-desktop { display: none; }

    .newsletter-page #HeaderText span {
        font-size: 36px;
        color: #ffff;
        font-family: sans-serif;
        background-color: #2480BB;
        background-clip: padding-box;
        margin-right: 30px;
    }
    
    .newsletter { margin: 20px 30px 50px; padding: 20px 50px; }
}

@media only screen and (max-width: 600px)
{
  .newsletter {  }
  .newsletter__inner { max-width: 360px; }

  .newsletter__heading--desktop { display: none; }
  .newsletter__heading--mobile { display: inline; max-width: 400px; }

  .newsletter__subheading { max-widths: 400px; margin-left: auto; margin-right: auto; }

  .newsletter__input { flex-basis: 100%; max-width: 100%; }
  .newsletter__province-container { flex-basis: 100%; max-width: 100%; }
}

@media only screen and (max-width: 560px)
{
    .newsletter { margin-left: 30px; margin-right: 30px; padding: 20px 30px; }
}

@media only screen and (max-width: 340px)
{
  .newsletter__submit { flex-basis: 100%; }
  .newsletter__submit-link { font-size: 22px; padding-left: 16px; padding-right: 16px; }
  .newsletter__submit-arrow { width: 70px; }
}