img {
    height: auto;
    width: 30px;
}

.Header {
    font-size: 2.5vw;
    font-family: 'Medium';
    color: #2480bb;
    position: absolute;
}

/* OUTER CONTAINER */
.tcontainer {
    width: 100%;
    overflow: hidden; /* Hide scroll bar */
    position: absolute;
    /*top: -3.5%;*/
    top:  0;
  }

  .tcontainer--strains-page {
      top: -1.5%;
    }
   
  /* MIDDLE CONTAINER */
  .ticker-wrap {
    width: 100%;
    padding-left: 100%; /* Push contents to right side of screen */
  }
  
  /* INNER CONTAINER */
  @keyframes ticker {
    0% { transform: translate3d(0, 0, 0); }
    100% { transform: translate3d(-100%, 0, 0); }
  }
  .ticker-move {
    /* Basically move items from right side of screen to left in infinite loop */
    display: inline-block;
    white-space: nowrap;
    padding-right: 100%;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: ticker;
    animation-duration: 30s;
  }
   /* 
  .ticker-move:hover{
    animation-play-state: paused;Pause scroll on mouse hover 
  }
  */
 
 .ticker-item p { margin-top: 5px; }
 
  .BestSeller,
  .NewProduct { position: absolute; left: 0; top: 0; height: 100%; width: 20px; background: #d0ff00; borders:solid 1px red; z-index: 2; }
  .NewProduct { background: #f77fff; }
  .BestSeller__text,
  .NewProduct__text { transform: rotates(-90deg); margin-left: 8px; borders:solid 1px red; height: 20px; color: #000; }

  @keyframes ticker_vertical {
    0% { transform: translateY(2600px) rotate(-90deg); }
    100% { transform: translateY(1400px) rotate(-90deg); }
  }
  @keyframes ticker_vertical_2 {
    0% { transform: translateY(3000px) rotate(-90deg); }
    100% { transform: translateY(1800px) rotate(-90deg); }
  }
  .ticker-move-vertical {
    /* Basically move items from right side of screen to left in infinite loop */
    display: block;
    white-space: nowrap;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: ticker_vertical;
    animation-duration: 40s;
  }

  .NewProduct__text.ticker-move-vertical { animation-name: ticker_vertical_2; }
  
  /* ITEMS */
  .ticker-item{
    display: inline-block; /* Lay items in a horizontal line */
    padding: 0 2rem;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    font-family: Medium;
    font-size: min(2.5vw, 50px);
    color: #2480bb;
  }
  .ticker-item p { margin-bottom: 0; }

  @media screen and (max-width: 992px) {
    img {
      height: auto;
      width: 30px;
  }
  
  .Header {
      font-size: 30px;
      font-family: 'Medium';
      color: #2480bb;
      position: absolute;
  }
  
  /* OUTER CONTAINER */
  .tcontainer {
      width: 100%;
      overflow: hidden; /* Hide scroll bar */
      position: absolute;
      /*top: -2.5%;*/
      top: -28px;
    }
     
    /* MIDDLE CONTAINER */
    .ticker-wrap {
      width: 100%;
      padding-left: 100%; /* Push contents to right side of screen */
    }
    
    /* INNER CONTAINER */
    @keyframes ticker {
      0% { transform: translate3d(0, 0, 0); }
      100% { transform: translate3d(-100%, 0, 0); }
    }
    .ticker-move {
      /* Basically move items from right side of screen to left in infinite loop */
      display: inline-block;
      white-space: nowrap;
      padding-right: 100%;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-name: ticker;
      animation-duration: 20s;
    }
     /* 
    .ticker-move:hover{
      animation-play-state: paused;Pause scroll on mouse hover 
    }
    */
    
    /* ITEMS */
    .ticker-item{
      display: inline-block; /* Lay items in a horizontal line */
      padding: 0 2rem;
      text-decoration: underline;
      text-decoration-thickness: 2px;
      font-family: Medium;
      font-size: 30px;
      color: #2480bb;
    }
  }
  


.ProductTitleMobile { font-family: Medium, Arial, Helvetica, sans-serif; }