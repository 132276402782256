:root {
    --diesel-dough-color  : #a55a2d;
    --grape-diamonds-color: #2480bb;
    --moontang-color      : #a65a27;
    --durban-lime-color   : #0d6158;
    --lemon-diesel-color  : #eebc34;
    --zombie-kush-color   : #000000;
    --banjo-color         : #eebc34;
    --race-fuel-og-color  : #000000;
    --jungle-cake-color   : #0d6158;
    --kiwi-kush-color     : #b487b0; /* very close to the other purple but Katherine asked specifically for this color for this one */

    --green               : #0d6158;
    --green-hover         : #02544c;

    /*--green-darker      : #1a6258;
    --green-darker-hover  : #1a6258;*/
    
    --purple              : #b685ab;
    --purple-hover        : #a57a9b;

    --beige               : #f1efd9;
    --beige-hover         : #d1cfb9;

    --beige-darker        : #d1d0b5;
    --beige-darker-hover  : #c7c6aa;

    --blue                : #2480BB;
    --blue-hover          : #2071a5;

    --brown               : #A55A2D;
    --brown-hover         : #8d4c23;

    --yellow              : #eebc34;
    --yellow-hover        : #cda32a;
}

.FlavoursNavBar {
    margin: 0 0 0 0;
    /*float: right;
    width: 78%;*/
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
}

.NavBarFlavourRow {
    font-size: 20px;
    font-weight: 900;
    display: flex;
    flex-direction: row;
    display: flex;
    /*max-width: 80%;*/
}

.NavBarFlavour {
    padding-left: 15px;
    flex-basis: 33%;
}

.NavBarFlavourRow > .NavBarFlavour:first-child { padding-left: 0; }

.NavBarFlavourRow .NavBarFlavour a {
    text-decoration: none;
}

.NavBarFlavour__text { text-align: center; }

.NavBarFlavour__text--blue {
    background-color: #2480BB;
    color: #B586A9;

}
.NavBarFlavour__text--blue:hover {
    background-color: #2071a5;    
}

.NavBarFlavour__text--brown {
    background-color: #A55A2D;
    color: var(--blue);
}
.NavBarFlavour__text--brown:hover {
    background-color: #8d4c23;
}

.NavBarFlavour__text--yellow {
    background-color: #EEBC34;
}
.NavBarFlavour__text--yellow:hover {
    background-color: #cda32a;
}

.NavBarFlavour__text--green {
    background-color: var(--green);
    color: var(--beige);
}
.NavBarFlavour__text--green:hover {
    background-color: var(--green-hover);
}

.NavBarFlavour__text--beige {
    color: var(--blue);
    background-color: var(--beige);
}
.NavBarFlavour__text--beige:hover {
    background-color: var(--beige-hover);
}

.NavBarFlavour__text--beige-darker {
    color: var(--purple);
    background-color: var(--beige-darker);
}
.NavBarFlavour__text--beige-darker:hover {
    background-color: var(--beige-darker-hover);
}

.NavBarFlavour__text--purple {
    color: var(--beige);
    background-color: var(--purple);
}
.NavBarFlavour__text--purple:hover {
    background-color: var(--purple-hover);
}

.NavBarFlavour__text--city-slicker {
    background-color: #ffc62b;
    color: #218dc2;
}
.NavBarFlavour__text--city-slicker:hover {
    background-color: #edb622;
}

.NavBarFlavour__text--fuzzy-hammer {
    background-color: var(--beige-darker);
    color: var(--green);
}
.NavBarFlavour__text--fuzzy-hammer:hover {
    background-color: var(--beige-darker-hover);
}

.NavBarFlavour__text--yogurt {
    background-color: #1e80bf;
    color: #efbf1b;
}
.NavBarFlavour__text--yogurt:hover {
    background-color: #1570ab;
}

.NavBarFlavour__text--hash-burger {
    background-color: var(--brown);
    color: #efbf1b;
}
.NavBarFlavour__text--hash-burger:hover {
    background-color: var(--brown-hover);
}

.DiamondDustOG {
    color: #D2CDb3;
    background-color: #2480bb;
}

.OGKush {
    color: #D2CDb3;
    background-color: #B586A9;
}

.CactusBreath {
    background-color: #006158;
    color: #191718;
}

.SourKush {
    color: #EEBC34;
    background-color: #A55A2D;
}

.LakeTahoeOG {
    background-color: #2480BB;
    color: #0D6158;
}

.BlueLemonThai {
    background-color: #0D6158;
    color: #d2cDb3;
}

.AmherstSuperSkunk {
    background-color: #d1ccb3;
    color: #000000;
}

.SundaeDriver {
    color: #f6bb3e;
    background-color: #B586A9;
}

.PurplePunch {
    background-color: #000000;
    color: #B586A9;
}

.GarlicBreath {
    background-color: #2480BB;
    color: #B586A9;

}

.GreaseMonkey {
    background-color: #EEBC34;
    color: #0D6158;
}

.GrilledCheese {
    background-color: #A55A2D; 
    color: #EEBC34;

}

.GrandaddyBlack {
    background-color: #000;
    color: #A55A2D;
}

.StickyBuns {
    background-color: #EEBC34;
    color: #000;
}

.GrapeDiamonds {
    background-color: var(--grape-diamonds-color);
    color: #B586A9;
}
.Moontang {
    background-color: var(--moontang-color);
    color: #B586A9;
}
.DurbanLime {
    background-color: var(--durban-lime-color);
    color: #EEBC34;
}
.LemonDiesel {
    background-color: var(--lemon-diesel-color);
    color: #000;
}
.ZombieKush {
    background-color: var(--zombie-kush-color);
    color: #d2cDb3;
}
.RaceFuelOG {
    background-color: var(--race-fuel-og-color);
    color: #d2cDb3;
}
.Banjo {
    background-color: var(--banjo-color);
    color: #0d6158;
}
.DieselDough {
    background-color: var(--diesel-dough-color);
    color: #000;
}
.KiwiKush {
    background-color: var(--kiwi-kush-color);
    color: #0d6158;
}

@media screen and (max-width: 700px) {
    .FlavoursNavBar {
        display: none;
        margin: 230px auto 0px auto;
        /*float: left;*/
        width: 100%;
        /*display: flex;*/
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: sans-serif;
        padding-left: 10%;
    }
    .FlavoursNavBarInner {
        width: 85%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: none;
    }
    
    .NavBarFlavourRow {
        font-size: 13px;
        font-weight: 900;
        display: flex;
        flex-direction: row;
        width: 100%;
        max-width: 100%;
    }

    .NavBarFlavour {
        padding-left: 15px;
        flex-basis: 33%;
    }


}

.OGKush:hover {
    background-color: #9e7594;
}

.SourKush:hover {
    background-color: #8d4c23;
}

.LakeTahoeOG:hover {
    background-color: #2071a5;
}

.CactusBreath:hover {
    background-color: #00463f;
}

.BlueLemonThai:hover {
    background-color: #02544c;
}

.AmherstSuperSkunk:hover {
    background-color: #c1bca4;
}

.SundaeDriver:hover {
    background-color: #9e7594;
}

/*.PurplePunch:hover {
    background-color: #b5b29d;
}*/


.StickyBuns:hover {
    background-color: #cda32a;
}


.GarlicBreath:hover {
    background-color: #2071a5;
}

.GreaseMonkey:hover {
    background-color: #cda32a;
}

.GrilledCheese:hover {
    background-color: #8d4c23;
}


.ProductTitle:not(.ProductTitle--text-inside) { margin: 0; line-height: 0.7; }
.ProductTitle--text-inside { margin: 20px 40px; }