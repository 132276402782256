:root {
    --pre-rolls-color: #b685ab;
}

.MobileProductInfo--flower { background-color: #EEBc34; }
.MobileProductInfo--pre-rolls { background-color: var(--pre-rolls-color); }

.ProductDescription__button--mobile { padding: 20px; margin-bottom: 20px; }


@media screen and (max-width: 700px) {
    .MobileProducts {
        position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 80%;
            margin: 250px 0px 0px 10%;
    }
    
    .MobileProductImage img {
        height: auto;
        width: 120px;
        padding-bottom: 15px;
    }
    
    .MobileProductInfo {
        
        width: 100%;
        height: 100%;
        padding: 20px;
        font-family: Regular;
        font-size: 16px;
    }

    .MobileProductInfo h1 {
        padding: 0px;
        margin: 0px;
    }
    
}