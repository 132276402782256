.JungleCakePage {
    margin: 1.5% 0% 0px 0px;
    /*float: right;
    width: 78%;*/
    display: flex;
    flex-direction: column;
}

.JungleCakeContent {
    /*float: left;
    width: 80%;*/
    height: auto;
    background-color: var(--durban-lime-color);
}

.JungleCakeContent img {
    width: 400px;
    padding: 40px;
}

.JungleCakeText {
    padding: 40px;
    padding-top: 0px;
    font-size: 1.1em;
    font-family: Regular;
    color: #d1ccb3;
}

.JungleCakeText table {
    width: 100%;
    text-align: left;
    font-family: Regular;
}



@media screen and (max-width: 700px) {
    .JungleCakePage {
        margin: 0 0% 0px 0px;
        padding-top: 250px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .JungleCakeContent {
        width: 85%;
        height: auto;
        background-color: var(--durban-lime-color);
    }
    
    .JungleCakeContent img {
        width: 330px;
    }
    
    .JungleCakeText {
        padding: 30px;
        text-align: justify;
        padding-top: 0px;
        font-size: 1.1em;
        font-family: Regular;
    }
    
    .JungleCakeText table {
        width: 100%;
        text-align: left;
        font-family: Regular;
    }
    
    #FeatureHeaders {
        text-decoration: underline;
        text-decoration-thickness: 2px;
        font-family: Bold;
        font-size: 1em
    }
    
    #FeatureSubHeaders {
        text-decoration: underline;
        text-decoration-thickness: 2px;
    }
}




