/* Defining the background space and modal space*/

.AgeGate {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.AgeGateContent {
    background-color: #A55A2D;
    color: #EEBC34;
    text-align: center;
    width: 50%;
    padding: 35px;
    font-size: 1.2vw;
}

/* Defining dropdowns space/position and appearance */

.Dropdown {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.RightSideDropdown, .LeftSideDropdown {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 50%;
}

#Province {
    height: 50px;
    font-size: 1em;
    padding-left: 20px;
    appearance: none;
    width: 100%;
}

/* .ProvinceWrapper is needed to style the dropdown arrow on the selects */
.ProvinceWrapper { position: relative; display: inline-block; }
.ProvinceWrapper::after {
    position: absolute;
    content: '>';
    font: 20px "Consolas", monospace;
    color: #000;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    top: 13px;
    right: 20px;
    pointer-events: none;
}

.BirthdayInputs {
    display: flex;
    height: 50px;
    appearance: none;
}

.BirthdayInputs input {
    font-size: 1em;
    width: 30%;
    text-align: center;
}

/* Set sizes for images (TableTop Logo, Enter Site Button */

#TableTopLogoText {
    width: 60%;
    height: auto;
}

input[type=image],
.EnterSiteButton {
    background-image: url('../../../assets/img/hover_buttons/BlueHover.svg');
    background-image: url('../../../assets/img/blue-button.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 200px;
    height: 185px;
    cursor: pointer;
    background-color:  transparent;
    border:  none;
}
input[type=image]:hover,
input[type=image]:active,
input[type=image]:focus,
.EnterSiteButton:hover,
.EnterSiteButton:active,
.EnterSiteButton:focus {
    background-image: url('../../../assets/img/hover_buttons/BlueHover.svg');
}
.Disclaimer {
    padding: 0px 100px 0px 100px;
}

.Disclaimer p {
    font-size: 24px;
}

.AgeGate a {
    color: #EEBC34;
} 


@media screen and (max-width: 700px) {
    .AgeGate {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
    }
    
    .AgeGateContent {
        background-color: #A55A2D;
        color: #EEBC34;
        text-align: center;
        width: 70%;
        padding: 35px;
        font-size: 15px;
    }
    
    /* Defining dropdowns space/position and appearance */
    
    .Dropdown {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .LeftSideDropdown {
        padding-bottom: 20px;
    }
    
    .RightSideDropdown, .LeftSideDropdown {
        display: flex;
        flex-direction: column;
        text-align: left;
        width: 100%;
    }
    
    #Province {
        height: 50px;
        font-size: 1em;
        padding-left: 20px;
        appearance: none;
    }
    
    .BirthdayInputs {
        display: flex;
        height: 50px;
        appearance: none;
    }
    
    .BirthdayInputs input {
        font-size: 1em;
        text-align: center;
    }
    
    /* Set sizes for images (TableTop Logo, Enter Site Button */
    
    #TableTopLogoText {
        width: 100%;
        height: auto;
    }
    
    #EnterSiteButton {
        width: 60%;
        height: auto;
    }
    
    .Disclaimer {
        padding: 0px;
    }
    
    .AgeGate a {
        color: #EEBC34;
    }
    
    .ConstantTermsAndConditions {
        display: none;
    }
  }

  @media screen and (min-width: 700px) {
      .MobileConstantTermsAndConditions {
          display: none;
      }
  }