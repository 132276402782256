.SanFranSourDoughPage {
    margin: 1.5% 0% 0px 0px;
    /*float: right;
    width: 78%;*/
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.SanFranSourDoughContent {
    /*float: left;
    width: 80%;*/
    height: auto;
    background-color: var(--beige-darker);
    position: relative;
    overflow: hidden;
}

.SanFranSourDoughContent img {
    width: 400px;
    padding: 40px;
}

.SanFranSourDoughText {
    padding: 40px;
    padding-top: 0px;
    font-size: 1.1em;
    font-family: Regular;
    color: #000000;
}

.SanFranSourDoughText table {
    width: 100%;
    text-align: left;
    font-family: Regular;
}



@media screen and (max-width: 700px) {
    .SanFranSourDoughPage {
        margin: 0 0% 0px 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 250px;
    }
    
    .SanFranSourDoughContent {
        width: 85%;
        height: auto;
        background-color: var(--beige-darker);
    }
    
    .SanFranSourDoughContent img {
        width: calc(100% - 60px);
        max-width: 330px;
        padding: 30px;
    }
    
    .SanFranSourDoughText {
        padding: 30px;
        text-align: justify;
        padding-top: 0px;
        font-size: 1.1em;
        font-family: Regular;
    }
    
    .SanFranSourDoughText table {
        width: 100%;
        text-align: left;
        font-family: Regular;
    }
    
    #FeatureHeaders {
        text-decoration: underline;
        text-decoration-thickness: 2px;
        font-family: Bold;
        font-size: 1em
    }
    
    #FeatureSubHeaders {
        text-decoration: underline;
        text-decoration-thickness: 2px;
    }
}




