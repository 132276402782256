:root {
    --body-font-size-stretchy: clamp(16px, 1.4vw, 30px);

    

}


.sidebar-and-content-wrapper { display: flex; margin: 100px 50px 0; max-width: 1120px; gap: min(3vw, 50px); }
body.home-page .sidebar-and-content-wrapper { max-width: unset; }

.SideBar {
    /*width: 15%;
    position: absolute;*/
    /*height: 75%;*/
    margin: 0px 0px 0px 0px;
    flex: 0 0 15%;
}

.PageLinks, .SocialMedia, .GuestBookVisitors {
    list-style: none;
    padding: 10px;
}

.PageLinks {
    /*width: 75%;*/
    box-shadow: 10px 10px 5px rgba(0,0,0,.6);
    background-color: #B586A9;
    margin-top: 0;
    margin-bottom: 12px;
}

.SocialMedia {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.GuestBookVisitors {
    margin-top: 0;
}

.PageLinks .ListItem a {
    text-decoration-color: #EEBC34;
    text-decoration-thickness: 2px;
    color: #EEBC34;
    font-size: min(2.5vw, 50px);
    padding: 10px;
}

.PageLinks .ListItem a:hover {
    color: #2480bb;
    text-decoration-color: #2480bb;
}

.PageLinks .ListItem .active {
    color: #fff;
    text-decoration-color: #fff;
}

.SocialMedia .ListItem {
    padding: 0px 0px 4px 0px;
    margin-bottom: 4px;
}

.GuestBookVisitors .ListItem {
    margin-bottom: 5px;
}

.SocialMedia .ListItem a {
    text-decoration: none;
    color: #fff;
    font-size: 18pt;
    /*text-shadow: 10px 10px 2px #404040;*/
    background:  black;
    box-shadow: 10px 10px 5px rgba(0,0,0,.6);
    line-height: 10pt;
    padding: 0;
    margin: 0;
}

.SocialMedia .ListItem a:hover {
    color: #EEBC34;
}



#AnimatedBook {
    height: auto;
    width: 50px;
    margin: 0px;
    padding-top: 150px;
}

@media screen and (max-width: 700px) {
    #AnimatedBook {
        padding-top: 0;
    }
}

#GuestBook {
    height: auto;
    width: min(100%, 130px);
    -webkit-filter: drop-shadow( 8px 8px 5px rgba(0, 0, 0, .7));
    filter: drop-shadow( 8px 8px 7px rgba(0, 0, 0, .7));

}

#Visitors {
    height: auto;
    width: min(100%, 180px);
}

.MobileFooter__newsletter-image {
    height: auto;
    width: min(100%, 180px);
}



.TableTopRight {
    float: right;
    right: 0;
    position: absolute;
}

.ConstantTermsAndConditions {
    /*position: absolute;*/
    font-size: 14px;
    color: #fff;
    display: flex;
    flex-direction: row;
    width: 100%;
    /*top: 120vh;*/
    /*transform: translateY(-100%);*/
}

body:is(.individual-strain-page, .buy-page) .ConstantTermsAndConditions {
    color: #000;
    position: relative;
}

body.buy-page .ConstantTermsAndConditions {
    color: #fff;
}


body:not(.individual-strain-page):not(.buy-page):not(.about-page):not(.products-page) .ConstantTermsAndConditions { /*position: absolute; top: 120vh; transform: translateY(-100%);*/ }

.ConstantTermsAndConditions:not(.ConstantTermsAndConditions--StrainsPage):not(.ConstantTermsAndConditions--GuestbookPage):not(.ConstantTermsAndConditions--BuyPage) {
    /*position: absolute;*/
    /*top: 120vh;*/
    /*transform: translateY(-100%);*/
    padding: 20px;
    width: calc(100% - 40px);
}

.ConstantTermsAndConditions a {
    text-decoration: none;
    color: #fff
}

body.individual-strain-page .ConstantTermsAndConditions a {
    color: #000;
    background: #fff;
}

.LeftDisclaimer {
    text-align: left;
    width: 50%;
}

.RightDisclaimer {
    text-align: right;
    width: 50%;
}

body.individual-strain-page .RightDisclaimer span {
    background: #fff;
}

.TableTopRight img {
    padding: 100px 40px 0px 0px;
    width: 75px;
    height: auto;
}

.VisitorsDiv {
    position: relative;
  text-align: center;
  color: black;
  display: inline;
}

.VisitorsText {
    position: absolute;
  bottom: 47%;
  left: 47%;
  transform: translate(-50%, -50%);
  font-size: min(1em, 20px);
}

@media only screen and (max-width: 1000px)
{
    .VisitorsText { transform: translate(-50%, -55%); font-size: min(.8em, 16px); }
}


/* this CSS file gets loaded into all pages (desktop anyway) so put global rules here */

.HeaderTextWrapper { display: flex; flex-direction: column; font-family: sans-serif; margin: 100px 0px 10px 0; float: right; width: 78%; } /* this wrapper is only needed on the individual product pages */
#HeaderText { box-shadow: 10px 10px 5px rgb(0 0 0 / 60%); background-color: #2480BB; /*max-width: 800px;*/ padding: 2px 8px; font-size: 25px; color: #ffff; font-family: sans-serif; background-color: #2480BB; background-clip: padding-box; box-sizing: border-box; }
#HeaderText a { text-decoration: underline !important; color: white !important; }
#HeaderText a:active { color: #eebc34 !important; }
/*.breadcrumb span { margin-right: 0 !important; padding: 0 .3em; }*/

/* on individual strain pages, the title/breadcrumb width needs to be different */
.HeaderTextWrapper > #HeaderText { width: calc(80% - 16px); max-width: 100% }


/* on individual strain pages, these are the category headings like "LINEAGE" and "PLANT TYPE" etc */
#FeatureHeaders { text-transform: uppercase; padding-bottom: 8px; }

/* on individual strain pages, these are the type headings like "Dried Flower" and "Pre-Roll" etc */
.feature-sub-headers-extra-top-padding { padding-top: 8px; }

/* on individual strain pages, in the grid of other strains that shows near top of page, we only show the ones that match this "type" (only show other pre-rolls if you're looking at a pre-roll-only product)

NOT USING THIS AS OF DEC-16-2022
 */
/*
.individual-strain-page--pre-rolls-only .FlavoursNavBarInner { display: none; }
.individual-strain-page--pre-rolls-only .FlavoursNavBarInner--pre-rolls-only { display: block; }

.individual-strain-page--flower-only .FlavoursNavBarInner { display: none; }
.individual-strain-page--flower-only .FlavoursNavBarInner--flower-only { display: block; }

.individual-strain-page--flower-and-pre-rolls .FlavoursNavBarInner { display: none; }
.individual-strain-page--flower-and-pre-rolls .FlavoursNavBarInner--flower-and-pre-rolls { display: block; }
*/


/* bg's for all? pages */

.home-page { background-image: url('../../Home/Background/Home.jpg'); background-repeat: repeat; background-size: 50%; }

.individual-strain-page { background-repeat: repeat; background-size: 45%; }

.individual-strain-page--san-fran-sour-dough { background-image: url('../../Flavours/FlavourComponents/SanFranSourDough/Background/SanFranSourDough.jpg'); background-size: 90%; }

.individual-strain-page--just-fine-grind-bold-sativa { background-image: url('../../Flavours/FlavourComponents/JustFineGrindBoldSativa/Background/JustFineGrindBoldSativa.jpg'); }

.individual-strain-page--just-fine-grind-bold-indica { background-image: url('../../Flavours/FlavourComponents/JustFineGrindBoldIndica/Background/JustFineGrindBoldIndica.jpg'); }

.individual-strain-page--allen-wrench { background-image: url('../../Flavours/FlavourComponents/AllenWrench/Background/AllenWrench.jpg'); }

.individual-strain-page--square-dance { background-image: url('../../Flavours/FlavourComponents/SquareDance/Background/SquareDance.jpg'); }

.individual-strain-page--just-fine-grind-sativa { background-image: url('../../Flavours/FlavourComponents/JustFineGrindSativa/Background/JustFineGrindSativa.jpg'); }

.individual-strain-page--just-fine-grind-indica { background-image: url('../../Flavours/FlavourComponents/JustFineGrindIndica/Background/JustFineGrindIndica.jpg'); }


.individual-strain-page--pretty-great-28 { background-image: url('../../Flavours/FlavourComponents/PrettyGreat28/Background/PrettyGreat28.jpg'); }

.individual-strain-page--diesel-dough { background-image: url('../../Flavours/FlavourComponents/DieselDough/Background/PreRolls.jpg'); }

.individual-strain-page--grape-diamonds { background-image: url('../../Flavours/FlavourComponents/GrapeDiamonds/Background/GrapeDiamonds.jpg'); background-size: 90%; }

.individual-strain-page--moontang { background-image: url('../../Flavours/FlavourComponents/Moontang/Background/Moontang.jpg'); }

.individual-strain-page--durban-lime { background-image: url('../../Flavours/FlavourComponents/DurbanLime/Background/DurbanLime.jpg'); background-size: 90%; }

.individual-strain-page--lemon-diesel { background-image: url('../../Flavours/FlavourComponents/LemonDiesel/Background/LemonDiesel.jpg'); background-size: 90%; }

.individual-strain-page--blue-lemon-thai { background-image: url('../../Flavours/FlavourComponents/BlueLemonThai/Background/BlueLemonThai.jpg'); background-size: 90%; }

.individual-strain-page--sticky-buns { background-image: url('../../Flavours/FlavourComponents/StickyBuns/Background/StickyBuns.jpg'); background-size: 90%; }

.individual-strain-page--grilled-cheese { background-image: url('../../Flavours/FlavourComponents/GrilledCheese/Background/GrilledCheese.jpg'); }

.individual-strain-page--zombie-kush { background-image: url('../../Flavours/FlavourComponents/ZombieKush/Background/ZombieKush.jpg'); background-size: 90%; }

.individual-strain-page--banjo { background-image: url('../../Flavours/FlavourComponents/Banjo/Background/Banjo.jpg'); }

.individual-strain-page--race-fuel-og { background-image: url('../../Flavours/FlavourComponents/RaceFuelOG/Background/RaceFuelOG.jpg'); background-size: 90%; }

.individual-strain-page--purple-punch { background-image: url('../../Flavours/FlavourComponents/PurplePunch/Background/PurplePunch.jpg'); background-size: 90%; }

.individual-strain-page--garlic-breath { background-image: url('../../Flavours/FlavourComponents/GarlicBreath/Background/GarlicBreath.jpg'); background-size: 90%; }

.individual-strain-page--grease-monkey { background-image: url('../../Flavours/FlavourComponents/GreaseMonkey/Background/GreaseMonkey.jpg'); }

.individual-strain-page--kiwi-kush { background-image: url('../../Flavours/FlavourComponents/KiwiKush/Background/KiwiKush.jpg'); background-size: 90%; }

.individual-strain-page--city-slicker { background-image: url('../../Flavours/FlavourComponents/CitySlicker/Background/CitySlicker.jpg'); background-size: 45%; }
.individual-strain-page--fuzzy-hammer { background-image: url('../../Flavours/FlavourComponents/FuzzyHammer/Background/FuzzyHammer.jpg'); background-size: 45%; }
.individual-strain-page--yogurt { background-image: url('../../Flavours/FlavourComponents/Yogurt/Background/Yogurt.jpg'); background-size: 90%; }
.individual-strain-page--hash-burger { background-image: url('../../Flavours/FlavourComponents/HashBurger/Background/HashBurger.jpg'); background-size: 90%; }


.buy-page { background-image: url('../../Buy/Background/Buy.gif'); background-repeat: repeat; background-size: 50%; }

.about-page { background-image: url('../../About/Background/About.jpg'); background-repeat: repeat; background-size: 25%; }

.products-page { background-image: url('../../Products/Background/Products.jpg'); background-repeat: repeat; background-size: 50%; }

.guestbook-page { background-image: url('../../Guestbook/Background/Guestbook.jpg'); background-repeat: repeat; background-size: 100%; }

.newsletter-page { background-image: url('../../Newsletter/Background/Newsletter.gif'); background-repeat: repeat; background-size: calc(100% / 3); }

@media only screen and (max-width: 700px)
{
    .home-page { background-size: 100%; }
    .about-page { background-size: 100%; }

    .newsletter-page { background-size: 100%; }
}