.MobileFlavours {
    position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80%;
        margin: 250px 0px 0px 10%;
}

.MobileFlavourCard {
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    position: relative;
}

.MobileFlavourCardName img {
    width: 200px;
    height: auto;
    padding-top: 8px;
}

.MobileFlavourCardButton img{
    width: 60px;
    height: auto;
    padding-top: 7px;
}