body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Medium, Arial, Helvetica, sans-serif 
}

@font-face {
    font-family: 'Regular';
    src: local('Regular'), url(./assets/fonts/Regular.otf) format('truetype');
}

@font-face {
    font-family: 'Bold';
    src: local('Bold'), url(./assets/fonts/Bold.otf) format('truetype');
}

@font-face {
    font-family: 'Medium';
    src: local('Medium'), url(./assets/fonts/Medium.otf) format('truetype');
}