
/* page overall width */
.buy-page .sidebar-and-content-wrapper { max-width: 1300px; }


.Buy {
    margin: 0px 0px 40px 0px;
    /*float: right;
    width: 78%;*/
}

#HeaderText {
/* INherited from About */
}

.BuyContent {
    display: flex;
    flex-direction: row;
    font-family: Regular;
    font-size: var(--body-font-size-stretchy);
    /*width: 800px;*/
}

.BuyText {
    background-color: #EEBc34;
    /*width: 80%;*/
    width: 100%;
    padding: 30px;
}

.ProvinceList {
    display: flex;
    position: relative;
}

.ProvinceList a {
    display: block;
    font-size: 2vw;
    padding: 8px;
    color: #000;    
    text-decoration: underline;
    text-decoration-thickness: 2px;
    position: relative;
}

#Unavailable {
    color: gray;
    text-decoration: line-through;
}


.BuyDivider { margin-top: 24px; margin-bottom: 24px; border-top: 1px solid #000; }


/* BC popup "TO FIND BZAM NEAR YOU EMAIL"; used on multiple pages (homepage and buy page) so it's not in the ".where" BEM block */
.find-in-bc-infobox { display: none; width: 46vw; /*max-width: 70vw;*/ text-align: center; font-size: 2vw; line-height: 1; background: #fff; color: black; padding: 14px 8px 6px 8px; top: 18px; /*position: absolute; left: 60px;*/ box-shadow: 0 0 6px 0px rgb(0 0 0 / 70%); z-index: 3; margin-top: 10px; }

@media only screen and (min-width: 1000px)
{
    .find-in-bc-infobox { width: 460px; font-size: 20px; }
}

.find-in-bc-infobox:hover { cursor: pointer; }

.find-in-bc-infobox.is-visible { display: block; }



.GoButton {
    margin-left: 10px;
    width: 70px;
    height: auto;
    transition: all .2s ease-in-out;
    vertical-align: bottom;
}

.GoButton:hover {
    cursor: pointer;
}



.hibuddy { width: 94%; /* leave room for the table-leg image */ /*height: 100%; on TT, this forces this to be too tall for its container */ position: relative; min-height: 600px; }
.hibuddy__iframe { position: absolute; top: 0; left: 0; bottom: 0; right: 0; width: 100%; height: 100%; }




@media only screen and (max-width: 1320px)
{
    .hibuddy { width: 100%; /* table-leg has vanished so this can go full-width */ }
}