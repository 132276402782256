.CactusBreathBackground {
    background-image: url('./CactusBreath.jpg');
    background-repeat: repeat;
    background-size: 45%;
    min-height: 120vh;
    width: 100%;
    z-index: -1;
    position: absolute;
}

@media screen and (max-width: 700px) {
    .CactusBreathBackground {
        background-image: url('./CactusBreath.jpg');
        background-repeat: repeat;
        background-size: 45%;
        min-height: 100vh;
        height: 1700px;
        width: 100%;
        z-index: -1;
        position: absolute;
    }
}