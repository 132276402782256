.GuestbookBackground {
    background-image: url('./Guestbook.jpg');
    background-repeat: repeat;
    background-size: 100%;
    min-height: 100vh;
    width: 100%;
    z-index: -1;
    position: absolute;
}

@media screen and (max-width: 700px) {
    body.guestbook-page { background-image: url('./Guestbook.jpg'); }
    .GuestbookBackground {
        /*height: 1500px;*/
        background-size: 100%;
    }
}