.YogurtPage {
    margin: 1.5% 0% 0px 0px;
    /*float: right;
    width: 78%;*/
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.YogurtContent {
    /*float: left;
    width: 80%;*/
    height: auto;
    background-color: #1e80bf;
    position: relative;
    overflow: hidden;
}

.YogurtContent img {
    max-height: 58px;
    width: auto;
    padding: 40px;
}

.YogurtText {
    padding: 40px;
    padding-top: 0px;
    font-size: 1.1em;
    font-family: Regular;
    color: #000000;
}

.YogurtText table {
    width: 100%;
    text-align: left;
    font-family: Regular;
}



@media screen and (max-width: 700px) {
    .YogurtPage {
        margin: 0 0% 0px 0px;
        padding-top: 250px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .YogurtContent {
        width: 85%;
        height: auto;
        background-color: #1e80bf;
    }
    
    .YogurtContent img {
        max-height: 54px;
        width: auto;
        padding: 30px;
    }
    
    .YogurtText {
        padding: 30px;
        text-align: justify;
        padding-top: 0px;
        font-size: 1.1em;
        font-family: Regular;
    }
    
    .YogurtText table {
        width: 100%;
        text-align: left;
        font-family: Regular;
    }
    
    #FeatureHeaders {
        text-decoration: underline;
        text-decoration-thickness: 2px;
        font-family: Bold;
        font-size: 1em
    }
    
    #FeatureSubHeaders {
        text-decoration: underline;
        text-decoration-thickness: 2px;
    }
}




