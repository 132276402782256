.AboutBackground {
    background-image: url('./About.jpg');
    background-repeat: repeat;
    background-size: 25%;
    min-height: 120vh;
    width: 100%;
    z-index: -1;
    position: absolute;
}

@media screen and (max-width: 700px) {
    .AboutBackground {
        height: 1500px;
        background-size: 100%;
    }
}