.MobileSideBar {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 75px 0px 0px 0px;
    z-index: 99;
}

.TableTopLogoMain  {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.TableTopLogoMain img {
    position: relative;
    height: auto;
    width: 80%;
}

.HamburgerMenu {
    margin-top: 10px;
    width: 80%;
    height: 300px;
}

.HamburgerMenuLink {
    width: 100%;
    height: 40px;
    box-shadow: 5px 5px 2.5px #404040;
    background-color: #B586A9;
    margin-bottom: 10px;
    color: #EEBC34;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 23px;
    text-decoration: underline;
    border: none;
    outline: none;
}

.HamburgerMenu .active {
    color: #fff;
    text-decoration-color: #fff;
}


