.LakeTahoeOGPage {
    margin: 1.5% 0% 0px 0px;
    /*float: right;
    width: 78%;*/
    display: flex;
    flex-direction: column;
}

.LakeTahoeOGContent {
    /*float: left;
    width: 80%;*/
    height: auto;
    background-color: #2480BB;
}

.LakeTahoeOGContent img {
    width: 500px;
}

.LakeTahoeOGText {
    padding: 40px;
    padding-top: 0px;
    font-size: 1.1em;
    font-family: Regular;
    color: #d1ccb3;
}

.LakeTahoeOGText table {
    width: 100%;
    text-align: left;
    font-family: Regular;
}

@media screen and (max-width: 700px) {
    .LakeTahoeOGPage {
        margin: 0 0% 0px 0px;
        padding-top: 250px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .LakeTahoeOGContent {
        width: 85%;
        height: auto;
        background-color: #2480BB;
    }
    
    .LakeTahoeOGContent img {
        width: 330px;
    }
    
    .LakeTahoeOGText {
        padding: 30px;
        text-align: justify;
        padding-top: 0px;
        font-size: 1.1em;
        font-family: Regular;
    }
    
    .LakeTahoeOG table {
        width: 100%;
        text-align: left;
        font-family: Regular;
    }
    
    #FeatureHeaders {
        text-decoration: underline;
        text-decoration-thickness: 2px;
        font-family: Bold;
        font-size: 1em
    }
    
    #FeatureSubHeaders {
        text-decoration: underline;
        text-decoration-thickness: 2px;
    }
}






