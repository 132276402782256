.Home {
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content:center;
    /*float: right;
    width: 95%;*/
    /*border:solid 1px red;*/
}

body:not(.home-page) .homepage-center-fixer { display: none; /* so it only shows on homepage */ }
body.home-page .homepage-center-fixer { flex-basis: 15%; }

.Lottie {
    /*position: absolute;*/
    /*margin: 50% 0% 0% 0%;*/
    margin-top: 20%;
    width: clamp(320px, 75%, 1000px);
}

@media screen and (max-width: 700px) {
    .Home {
        width: 100%;
        float: none;
    }

    .Lottie {
        width: 80%;
        top: 0;
        /*margin: 600px 0px 0px 0px;*/
    }

    .HomeMobileFooter {
        padding-tops: 400px;
    }
    
}