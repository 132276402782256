.MobileFooter {
    margin-top: 90px;
}

.MobileFooterVisitors, .MobileFooterSocialMedia {
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.MobileFooterVisitors #AnimatedBook {
    height: auto;
    width: 50px;
    padding-right: 20px;
}

.MobileFooterVisitors #Guestbook {
    height: auto;
    width: 100px;
    padding-right: 10px;
}


.MobileFooter__newsletter-image {
    height: auto;
    width: min(100%, 180px);
}
.MobileFooter__newsletter-image--mobile {
    width: min(100%, 130px);
    -webkit-filter: drop-shadow( 8px 8px 5px rgba(0, 0, 0, .7));
    filter: drop-shadow( 8px 8px 7px rgba(0, 0, 0, .7));
}

.MobileFooterVisitors #Visitors {
    height: auto;
    width: 130px;
}

.MobileFooterSocialMedia a {
    text-decoration: none;
    color: #fff;
    font-size: 17px;
    text-shadow: 10px 10px 2px #404040;
    padding-right: 15px;

}

body.buy-page .MobileFooterSocialMedia a,
body.individual-strain-page .MobileFooterSocialMedia a {
    color: #000;
}

.MobileFooterSocialMedia a:hover {
    color: #EEBC34;
}

.MobileConstantTermsAndConditions {
    font-size: 13px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
}

body.buy-page .MobileConstantTermsAndConditions,
body.individual-strain-page .MobileConstantTermsAndConditions {
    color: #000;
}


.MobileConstantTermsAndConditions a {
    text-decoration: none;
    color: #fff;
}

body.buy-page .MobileConstantTermsAndConditions a,
body.individual-strain-page .MobileConstantTermsAndConditions a {
    color: #000;
}