.YogurtBackground {
    background-image: url('./Yogurt.jpg');
    background-repeat: repeat;
    background-size: 45%;
    min-height: 120vh;
    width: 100%;
    z-index: -1;
    position: absolute;
}

@media screen and (max-width: 700px) {
    .YogurtBackground {
        background-image: url('./Yogurt.jpg');
        background-repeat: repeat;
        background-size: 45%;
        min-height: 100vh;
        height: 1870px;
        width: 100%;
        z-index: -1;
        position: absolute;
    }
}