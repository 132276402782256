@media screen and (max-width: 700px) {
    .MobileBuy {
        position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 80%;
            margin: 250px 0px 0px 10%;
    }
    
    #HeaderText span {
        margin-right: 5px;
    }
    
    .MobileBuyContent {
        background-color: #EEBc34;
        width: 100%;
    }
    
    .MobileBuyText {
        padding: 20px;
        font-size: 16px;
        font-family: Regular;
    }
    
    .MobileBuyText .ProvinceList .LeftList a{
        font-size: 20px;
    }



    /* BC popup "TO FIND BZAM NEAR YOU EMAIL"; used on multiple pages (homepage and buy page) so it's not in the ".where" BEM block */
    .MobileBuy .find-in-bc-infobox { width: 326px; max-width: calc(65vw - 24%); font-size: 16px; }

    .MobileBuy .find-in-bc-infobox.is-visible { display: block; }
    
    .MobileBuy .ProvinceWrapper::after { right: 14px; }
    .MobileBuy #Province { padding-left: 14px; padding-right: 36px; margin-bottom: 10px; font-size: 15px;}
    .MobileBuy .GoButton { margin-left: 10px; margin-bottom: 10px; }

    .MobileBuy .BuyDivider { margin-top: 14px; }

}


