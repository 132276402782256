.PrettyGreat28Page {
    margin: 1.5% 0% 0px 0px;
    /*float: right;
    width: 78%;*/
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.PrettyGreat28Content {
    /*float: left;
    width: 80%;*/
    height: auto;
    background-color: var(--purple);
    position: relative;
    overflow: hidden;
}

.PrettyGreat28Content img {
    width: 400px;
    padding: 40px;
}

.PrettyGreat28Text {
    padding: 40px;
    padding-top: 0px;
    font-size: 1.1em;
    font-family: Regular;
    color: #000000;
}

.PrettyGreat28Text table {
    width: 100%;
    text-align: left;
    font-family: Regular;
}



@media screen and (max-width: 700px) {
    .PrettyGreat28Page {
        margin: 0 0% 0px 0px;
        padding-top: 250px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .PrettyGreat28Content {
        width: 85%;
        height: auto;
        background-color: var(--purple);
    }
    
    .PrettyGreat28Content img {
        width: calc(100% - 60px);
        max-width: 330px;
        padding: 30px;
    }
    
    .PrettyGreat28Text {
        padding: 30px;
        text-align: justify;
        padding-top: 0px;
        font-size: 1.1em;
        font-family: Regular;
    }
    
    .PrettyGreat28Text table {
        width: 100%;
        text-align: left;
        font-family: Regular;
    }
    
    #FeatureHeaders {
        text-decoration: underline;
        text-decoration-thickness: 2px;
        font-family: Bold;
        font-size: 1em
    }
    
    #FeatureSubHeaders {
        text-decoration: underline;
        text-decoration-thickness: 2px;
    }
}




