.FlavoursBackground {
    /*background-image: url('./Flavours.jpg');
    background-repeat: repeat;
    background-size: 25%;*/
    min-height: 100vh;
    width: 100%;
    z-index: -1;
    position: absolute;
    /*border: solid 1px red;*/
    /*display: none;*/
}


body.strains-page {
    background-image: url('./Flavours.jpg');
    background-repeat: repeat;
    background-size: 25%;
}

@media screen and (max-width: 700px) {
    .FlavoursBackground {
        /*background-image: url('./Flavours.jpg');
        background-repeat: repeat;
        background-size: 100%;*/
        min-height: 100vh;
        width: 100%;
        height: 1300px;
        z-index: -1;
        position: absolute;
        border: solid 1px red;
        display: none
    }

    body.strains-page { 
        background-image: url('./Flavours.jpg');
        background-repeat: repeat;
        background-size: 100%;
    }

}