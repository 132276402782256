.ProductsBackground {
    background-image: url('./Products.jpg');
    background-repeat: repeat;
    background-size: 50%;
    min-height: 120vh;
    width: 100%;
    z-index: -1;
    position: absolute;
}

@media screen and (max-width: 700px) {
    .ProductsBackground {
        height: 1300px;
        background-size: 100%;
    }
}