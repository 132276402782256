.HashBurgerPage {
    margin: 1.5% 0% 0px 0px;
    /*float: right;
    width: 78%;*/
    display: flex;
    flex-direction: column;
}

.HashBurgerContent {
    /*float: left;
    width: 80%;*/
    height: auto;
    background-color: var(--brown);
    position: relative;
    overflow: hidden;
}

.HashBurgerContent img {
    box-sizing: border-box;
    width: 480px;
    max-width: 100%;
    padding: 40px;
}

.HashBurgerText {
    padding: 40px;
    padding-top: 0px;
    font-size: 1.1em;
    font-family: Regular;
    color: #d1ccb3;
}

.HashBurgerText table {
    width: 100%;
    text-align: left;
    font-family: Regular;
}



@media screen and (max-width: 700px) {
    .HashBurgerPage {
        margin: 0 0% 0px 0px;
        padding-top: 250px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .HashBurgerContent {
        width: 85%;
        height: auto;
        background-color: var(--brown);
    }
    
    .HashBurgerContent img {
        padding: 40px 30px;
        /*box-sizing: border-box;*/
        /*width: 100%;*/
        /*max-width: 100%;*/
    }
    
    .HashBurgerText {
        padding: 30px;
        text-align: justify;
        padding-top: 0px;
        font-size: 1.1em;
        font-family: Regular;
    }
    
    .HashBurgerText table {
        width: 100%;
        text-align: left;
        font-family: Regular;
    }
    
    #FeatureHeaders {
        text-decoration: underline;
        text-decoration-thickness: 2px;
        font-family: Bold;
        font-size: 1em
    }
    
    #FeatureSubHeaders {
        text-decoration: underline;
        text-decoration-thickness: 2px;
    }
}




