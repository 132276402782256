body {
    /*background-image: url(../../components/Guestbook/Background/Guestbook.jpg);*/
    background-size: 100%;
}

.Guestbook {
    margin: 0px 0px 40px 0px;
    /*float: right;
    width: 78%;*/
}

#HeaderText span {
    font-size: 4vw;
    color: #ffff;
    font-family: sans-serif;
    background-color: #2480BB;
    background-clip: padding-box;
    margin-right: 30px;
}

#HeaderText {
    margin-top: 0;
    margin-bottom: 20px;
    font-weight: normal;
    line-height: normal;
}



.GuestbookContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: Regular;
    /*height: 50%;*/
    /*min-height: 1000px;*/
    /*width: 800px;*/
}

.FullJustify {
    text-align: justify;
    margin-bottom:-1em !important;
  }
  .FullJustify:after {
    content: "";
    display: inline-block;
    width: 100%;
  }

.GuestbookText {
    width: 49.6%;
    background-color: #EEBc34;
    font-size: 1.2vw;
    padding: 30px;
}

.GuestbookText p, ul{
    font-size: var(--body-font-size-stretchy);
}

.ListElement mark {
    color: #ffff;
    font-family: Regular;
    background-color: #000;
}

.FeaturesList {
    padding-left: 10px;
}


#GuestbookiFrame {
    border: none;
}

#GuestbookiFrame input { 
    background-color: blue;
    color: white;
}


@media screen and (max-width: 700px) {
    .Guestbook {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80%;
        margin: 250px 0px 0px 10%;
    }
    
    #HeaderText span {
        font-size: 36px;
        color: #ffff;
        font-family: sans-serif;
        background-color: #2480BB;
        background-clip: padding-box;
        margin-right: 30px;
    }
    
    #HeaderText {
        margin-bottom: 20px;
    }
    
    
    
    .GuestbookContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: Regular;
        height: 50%;
        width: 100%;
    }
    
    .FullJustify {
        text-align: justify;
        margin-bottom:-1em !important;
      }
      .FullJustify:after {
        content: "";
        display: inline-block;
        width: 100%;
      }

    .GuestbookText {
        width: 80%;
        background-color: #EEBc34;
        padding: 30px;
    }

    .GuestbookText h1 {
        font-size: 17px !important;
    }
    
    .GuestbookText p, ul{
        font-size: 13px;
    }
    
    .ListElement mark {
        color: #ffff;
        font-family: Regular;
        background-color: #000;
    }
    
    .FeaturesList {
        padding-left: 10px;
    }
}