:root {
    --diesel-dough-color               : #a55a2d;
    --grape-diamonds-color             : #2480bb;
    --moontang-color                   : #a65a27;
    --durban-lime-color                : #0d6158;
    --lemon-diesel-color               : #eebc34;
    --zombie-kush-color                : #000000;
    --banjo-color                      : #eebc34;
    --race-fuel-og-color               : #000000;
    --jungle-cake-color                : #0d6158;
    --just-fine-grind-bold-indica-color: #0d6158;
    --just-fine-grind-bold-sativa-color: #000000;
    --san-fran-sour-dough-color        : #000000;
}

.Flavours {
    margin: 0px 0px 40px 0px;
    /*float: right;
    width: 78%;*/
    display: flex;
    flex-direction: column;
    /*height: 80vh;*/
    /*max-width: 800px;*/
}

.Flavours a {
    text-decoration: none; /* Where is the link inherting the text underline from? Is this default for <link />*/
    /*width: 800px;*/
}

.StrainTitle {
    display: flex;
    flex: 0 0 50%;
}

.StrainTitle * {
    box-sizing: border-box;
}

.StrainTitle img {
    margin-left: 0px;
    width: 100%;
    /*max-width: 375px;*/
    height: auto;
}

.LearnMore {
    display: flex;
    flex: 0 0 min(10vw, 100px);
}

.LearnMore img {
    width: 100%;
    height: auto;
    transition: all .2s ease-in-out;
}

/* On hover, change the content of the learn more image to the 'flattened' one */

body:after {
    position: absolute; overflow: hidden; left: -50000px;
    content: url('../../assets/img/hover_buttons/BeigeHover.svg'), url('../../assets/img/hover_buttons/BlackHover.svg'), url('../../assets/img/hover_buttons/BlueHover.svg'), url('../../assets/img/hover_buttons/BrownHover.svg'), url('../../assets/img/hover_buttons/GreenHover.svg'), url('../../assets/img/hover_buttons/PurpleHover.svg'), url('../../assets/img/hover_buttons/YellowHover.svg');
   }


/*   .OGKushCard .LearnMore img {
       content: url('../../assets/img/hover_buttons/BeigeHover.svg');
       content: url('../../assets/img/button-beige.svg');
   }
   .SourKushCard .LearnMore img {
       content: url('../../assets/img/hover_buttons/YellowHover.svg');
       content: url('../../assets/img/button-yellow.svg');
   }
   .LakeTahoeOGCard .LearnMore img {
       content: url('../../assets/img/hover_buttons/GreenHover.svg');
       content: url('../../assets/img/button-green.svg');
   }
   .BlueLemonThaiCard .LearnMore img {
       content: url('../../assets/img/hover_buttons/BeigeHover.svg');
       content: url('../../assets/img/button-beige.svg');
   }
   .PurplePunchCard .LearnMore img {
       content: url('../../assets/img/hover_buttons/PurpleHover.svg');
       content: url('../../assets/img/button-purple.svg');
   }
   .StickyBunsCard .LearnMore img {
       content: url('../../assets/img/hover_buttons/BlackHover.svg');
       content: url('../../assets/img/button-black.svg');
   }
   .GarlicBreathCard .LearnMore img {
       content: url('../../assets/img/hover_buttons/PurpleHover.svg');
       content: url('../../assets/img/button-purple.svg');
   }
   .GreaseMonkeyCard .LearnMore img {
       content: url('../../assets/img/hover_buttons/GreenHover.svg');
       content: url('../../assets/img/button-green.svg');
   }
   .GrilledCheeseCard .LearnMore img {
       content: url('../../assets/img/hover_buttons/YellowHover.svg');
       content: url('../../assets/img/button-yellow.svg');
   }
   .GrandaddyBlackCard .LearnMore img {
       content: url('../../assets/img/hover_buttons/BrownHover.svg');
       content: url('../../assets/img/button-brown.svg');
   }
   .CactusBreathCard .LearnMore img {
       content: url('../../assets/img/hover_buttons/BlackHover.svg');
       content: url('../../assets/img/button-black.svg');
   }

*/


.DiamondDustOG .LearnMore img:hover {
    /*content: url('../../assets/img/hover_buttons/BeigeHover.svg');*/
    transform: scale(0.95);
}

.OGKushCard .LearnMore img:hover {
    /*content: url('../../assets/img/hover_buttons/BeigeHover.svg');*/
    transform: scale(0.95);
}

.SourKushCard .LearnMore img:hover {
    /*content: url('../../assets/img/hover_buttons/YellowHover.svg');*/
    transform: scale(0.95);
}

.LakeTahoeOGCard .LearnMore img:hover {
    /*content: url('../../assets/img/hover_buttons/GreenHover.svg');*/
    transform: scale(0.95);
}

.BlueLemonThaiCard .LearnMore img:hover {
    /*content: url('../../assets/img/hover_buttons/BeigeHover.svg');*/
    transform: scale(0.95);
}

.PurplePunchCard .LearnMore img:hover {
    /*content: url('../../assets/img/hover_buttons/PurpleHover.svg');*/
    transform: scale(0.95);
}

.StickyBunsCard .LearnMore img:hover {
    /*content: url('../../assets/img/hover_buttons/BlackHover.svg');*/
    transform: scale(0.95);
}
.GarlicBreathCard .LearnMore img:hover {
    /*content: url('../../assets/img/hover_buttons/PurpleHover.svg');*/
    transform: scale(0.95);
}
.GreaseMonkeyCard .LearnMore img:hover {
    /*content: url('../../assets/img/hover_buttons/GreenHover.svg');*/
    transform: scale(0.95);
}
.GrilledCheeseCard .LearnMore img:hover {
    /*content: url('../../assets/img/hover_buttons/YellowHover.svg');*/
    transform: scale(0.95);
}
.GrandaddyBlackCard .LearnMore img:hover {
    /*content: url('../../assets/img/hover_buttons/BrownHover.svg');*/
    transform: scale(0.95);
}
.CactusBreathCard .LearnMore img:hover {
    /*content: url('../../assets/img/hover_buttons/BlackHover.svg');*/
    transform: scale(0.95);
}







.ProductLearnMoreCard {
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.ProductLearnMoreCard * { box-sixing: border-box; }

.ProductLearnMoreCard--blue {
    background-color: #2480BB;
    color: #000;

}
.ProductLearnMoreCard--blue:hover {
    background-color: #2071a5;    
}

.ProductLearnMoreCard--brown {
    background-color: var(--green);
    color: #000000;
}
.ProductLearnMoreCard--brown:hover {
    background-color: var(--brown-hover);
}

.ProductLearnMoreCard--beige {
    background-color: #f1efd9;
    color: #000000;
}
.ProductLearnMoreCard--beige:hover {
    background-color: #d1cfb9;
}

.ProductLearnMoreCard--beige-darker {
    background-color: var(--beige-darker);
}
.ProductLearnMoreCard--beige-darker:hover {
    background-color: var(--beige-darker-hover);
}

.ProductLearnMoreCard--green {
    background-color: #0D6158;
    color: #fff;
}
.ProductLearnMoreCard--green:hover {
    background-color: #02544c;
}

.ProductLearnMoreCard--green-darker {
    background-color: var(--green);
    color: #fff;
}
.ProductLearnMoreCard--green-darker:hover {
    background-color: var(--green-hover);
}

.ProductLearnMoreCard--yellow {
    background-color: #EEBC34;
    color: #000000;
}
.ProductLearnMoreCard--yellow:hover {
    background-color: #cda32a;
}

.ProductLearnMoreCard--purple {
    background-color: #b685ab;
    color: #000;
}
.ProductLearnMoreCard--purple:hover {
    background-color: #a57a9b;
}

.ProductLearnMoreCard--city-slicker {
    background-color: #ffc62b;
    color: #218dc2;
}
.ProductLearnMoreCard--city-slicker:hover {
    background-color: #edb622;
}

.ProductLearnMoreCard--fuzzy-hammer {
    background-color: var(--beige-darker);
    color: var(--green);
}
.ProductLearnMoreCard--fuzzy-hammer:hover {
    background-color: var(--beige-darker-hover);
}

.ProductLearnMoreCard--yogurt {
    background-color: #1e80bf;
    color: #efbf1b;
}
.ProductLearnMoreCard--yogurt:hover {
    background-color: #1570ab;
}
.ProductLearnMoreCard--hash-burger {
    background-color: var(--brown);
    color: #efbf1b;
}
.ProductLearnMoreCard--hash-burger:hover {
    background-color: var(--brown-hover);
}


.DieselDoughCard {
    background-color: var(--diesel-dough-color);
    color: #000;
}

.JustFineGrindBoldSativaCard {
    color: #000;
}
.SanFranSourDoughCard {
    color: #000;
}
.GrapeDiamondsCard {
    background-color: var(--grape-diamonds-color);
    color: #000;
}
.MoontangCard {
    background-color: var(--moontang-color);
    color: #000;
}
.DurbanLimeCard {
    background-color: var(--durban-lime-color);
    color: #ffffff;
}
.LemonDieselCard {
    background-color: var(--lemon-diesel-color);
    color: #000;
}
.ZombieKushCard {
    background-color: var(--zombie-kush-color);
    color: #d1ccb3;
}
.BanjoCard {
    background-color: var(--banjo-color);
    color: #000;
}
.RaceFuelOGCard {
    background-color: var(--race-fuel-og-color);
    color: #d1ccb3;
}
.JungleCakeCard {
    background-color: var(--jungle-cake-color);
    color: #000;
}

.DiamondDustOGCard {
    background-color: #2480bb;
    color: #000;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.DiamondDustOGCard:hover {
    background-color: #2071a5;
}

.ProductLearnMoreCard__strain-logo {
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.ProductLearnMoreCard .ProductLearnMoreCard__strain-logo--grape-diamonds,
.ProductLearnMoreCard .ProductLearnMoreCard__strain-logo--moontang,
.ProductLearnMoreCard .ProductLearnMoreCard__strain-logo--durban-lime,
.ProductLearnMoreCard .ProductLearnMoreCard__strain-logo--lemon-diesel,
.ProductLearnMoreCard .ProductLearnMoreCard__strain-logo--zombie-kush,
.ProductLearnMoreCard .ProductLearnMoreCard__strain-logo--race-fuel-og,
.ProductLearnMoreCard .ProductLearnMoreCard__strain-logo--banjo { padding-left: 40px; padding-right: 38px; /*max-width: 297px;*/ }

.ProductLearnMoreCard .ProductLearnMoreCard__strain-logo--banjo { max-height: 34px; }

.ProductLearnMoreCard .ProductLearnMoreCard__strain-logo--diesel-dough { padding-left: 40px; padding-right: 38px; /*width: 297px;*/ }

.DiamondDustOGCard .StrainTitle img {
    padding-left: 40px;
    padding-right: 40px;
    max-width: 295px;
    height: auto;
}


.OGKushCard {
    background-color: #b586a9;
    color: #000;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.OGKushCard:hover {
    background-color: #9e7594;
}

.SourKushCard {
    background-color: #A55A2D;
    color: #fff;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.SourKushCard:hover {
    background-color: #8d4c23;
}

.LakeTahoeOGCard {
    background-color: #2480BB;
    color: #fff;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.LakeTahoeOGCard:hover {
    background-color: #2071a5;
}

.BlueLemonThaiCard {
    background-color: #0D6158;
    color: #fff;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.BlueLemonThaiCard:hover {
    background-color: #02544c;
}

.PurplePunchCard {
    background-color: #000000; /* #D2CDb3;*/
    color: #d1ccb3;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

/*.PurplePunchCard:hover {
    background-color: 000;
}*/

.CactusBreathCard {
    background-color: #006158;
    color: #000;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.CactusBreathCard:hover {
    background-color: #00463f;
}

.CactusBreathCard .StrainTitle img {
    padding-left: 40px;
    padding-right: 60px;
    max-width: 275px;
    height: auto;
}

.StickyBunsCard {
    background-color: #EEBC34;
    color: #000;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.StickyBunsCard:hover {
    background-color: #cda32a;
}


.GarlicBreathCard {
    background-color: #2480BB;
    color: #000;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.GarlicBreathCard:hover {
    background-color: #2071a5;
}

.GreaseMonkeyCard {
    background-color: #EEBC34;
    color: #000;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.GreaseMonkeyCard:hover {
    background-color: #cda32a;
}

.GreaseMonkeyCard .StrainTitle img {
    padding-left: 40px;
    padding-right: 38px;
    max-width: 297px;
    height: auto;
}

.GrilledCheeseCard {
    background-color: #A55A2D;
    color: #000;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.GrilledCheeseCard:hover {
    background-color: #8d4c23;
}

.GrandaddyBlackCard {
    background-color: #000;
    color: #fff;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.AmherstSuperSkunkCard {
    background-color: #d1ccb3;
    color: #000;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.AmherstSuperSkunkCard:hover {
    background-color: #b5b29d;
}

.AmherstSuperSkunkCard .StrainTitle img {
    padding-left: 40px;
    padding-right: 40px;
    max-width: 295px;
    height: auto;
}

.SundaeDriverCard {
    background-color: #b586a9;
    color: #000;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.SundaeDriverCard:hover {
    background-color: #9e7594;
}

.SundaeDriverCard .StrainTitle img {
    padding-left: 40px;
    padding-right: 40px;
    max-width: 295px;
    height: auto;
}

.Amounts {
    display: flex;
    flex-direction: row;
    font-size: 1em;
    /*width: 100%;*/
    flex: 1;
    /*padding: 20px;*/
}

.Amounts ul{
    list-style: none;
    font-size: 16px;
}

#AmountHeader {
    text-decoration: underline;
    text-decoration-size: 2px;
}

.AmountVape {
    width: 50%;
    padding-right: 0px;
}

.AmountFlower {
    width: 50%;
    padding-right: 8px;
}

@media screen and (max-width: 1320px) {
    .TableTopRight {
        display: none;
    }
}

@media only screen and (max-width: 1200px)
{
    .AmountFlower ul { padding-left: 20px; }
    .AmountVape ul { padding-left: 20px; }
}

@media only screen and (max-width: 959px)
{
    .Amounts ul { font-size: 14px; }
    .AmountFlower ul { padding-left: 10px; }
    .AmountVape ul { padding-left: 10px; }

}

@media only screen and (max-width: 800px)
{
    .StrainTitle { flex: 0 1 46%; }
}