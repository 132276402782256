.About {
    margin: 0px 0px 0px 0px;
    /*float: right;
    width: 78%;*/
}

#HeaderText span {
    font-size: 24px;
    color: #ffff;
    font-family: sans-serif;
    background-color: #2480BB;
    background-clip: padding-box;
    margin-right: 30px;
}

#HeaderText {
    margin-bottom: 20px;
}

#Bob {
    display: block;
  width: 40.6%;
  height: auto;
    padding: 0;
    margin-right: 20px;
    border: 3px solid #EEBc34;
}

.AboutContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: Regular;
    /*height: 50%;
    width: 94%;*/
    /*max-width: 800px;*/
}

.FullJustify {
    text-align: justify;
    margin-bottom:-1em !important;
  }
  .FullJustify:after {
    content: "";
    display: inline-block;
    width: 100%;
  }

.AboutText {
    width: 49.6%;
    background-color: #EEBc34;
    font-size: 1.2vw;
    padding: 30px;
}

.AboutText p, ul{
    font-size: var(--body-font-size-stretchy);
}

.ListElement mark {
    color: #ffff;
    font-family: Regular;
    background-color: #000;
}

.FeaturesList {
    padding-left: 10px;
}

@media screen and (max-width: 700px) {
    .About {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80%;
        margin: 250px 0px 0px 10%;
    }
    
    #HeaderText span {
        font-size: 36px;
        color: #ffff;
        font-family: sans-serif;
        background-color: #2480BB;
        background-clip: padding-box;
        margin-right: 30px;
    }
    
    #HeaderText {
        margin-bottom: 20px;
    }
    
    #Bob {
        display: block;
      width: 100%;
      height: auto;
        padding: 0px 0px 0px 0px;
        margin: 0 0 20px 0;
    }
    
    .AboutContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: Regular;
        height: 50%;
        width: 100%;
    }
    
    .FullJustify {
        text-align: justify;
        margin-bottom:-1em !important;
      }
      .FullJustify:after {
        content: "";
        display: inline-block;
        width: 100%;
      }

    .AboutText {
        width: 80%;
        background-color: #EEBc34;
        padding: 30px;
    }

    .AboutText h1 {
        font-size: 20px !important;
    }
    
    .AboutText p, ul{
        font-size: 16px;
    }
    
    .ListElement mark {
        color: #ffff;
        font-family: Regular;
        background-color: #000;
    }
    
    .FeaturesList {
        padding-left: 10px;
        font-size: 16px;
    }
    /*.FeaturesList li mark { font-size: 16px !important; }*/
}