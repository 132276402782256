.BuyBackground {
    background-image: url('./Buy.gif');
    background-repeat: repeat;
    background-size: 50%;
    min-height: 120vh;
    width: 100%;
    z-index: -1;
    position: absolute;
}

@media screen and (max-width: 700px) {
    .BuyBackground {
        background-size: 100%;
        min-height: 100vh;
        width: 100%;
        height: 1350px;
        z-index: -1;
        position: absolute;
    }
}